<template>

    <v-dialog v-model="showDialog" max-width="800px">
        <v-card>
            <v-card-title>
                Editar {{product_name}}
            </v-card-title>
            <v-card-text v-if="mode == 'product'">
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                            v-model="product.name"
                            :rules="nameRules"
                            label="Nombre"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="product.sku"
                            :rules="nameRules"
                            label="SKU"
                            required
                            
                        ></v-text-field>
                    </v-col>
                     <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="product.ext_sku"
                            label="SKU externo"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="product.ean"
                            label="EAN"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                            v-model="product.price"
                            :rules="priceRules"
                            label="Precio"
                            required
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="product.high"
                            :rules="priceRules"
                            label="Altura"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="product.width"
                            :rules="priceRules"
                            label="Ancho"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="product.depth"
                            :rules="priceRules"
                            label="Profundidad"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="product.weight"
                            :rules="priceRules"
                            label="Peso"
                            required
                            suffix="kg"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" >
                        <v-select
                            v-model="product.category_id"
                            :items="categoriesList"
                            :rules="categoryRules"
                            label="Categoría"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete
                            :items="brandsList"
                            v-model="product.brand_id"
                            label="Marca"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <img style="max-width:100%; width:150px;" v-bind:src="product.imagecover_url_preview" /> 
                    </v-col>
                     <v-col cols="6" md="4">
                        <v-file-input
                        v-model="product.imagecover"
                        color="deep-purple accent-4"
                        label="Subir Foto Producto"
                        placeholder="Sube una archivo jpeg o png."
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                        @change="uploadFileProduct()"
                        :disabled="loading"
                        :clearable="true"
                        >
                        </v-file-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                    <v-btn color="primary" :disabled="loading" @click="sendFormProduct">Guardar cambios</v-btn>
                    </v-col>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-switch  v-model="product.active"  :label="'Activo'"></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="mode == 'bundle'">
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                            v-model="bundle.name"
                            :rules="nameRules"
                            label="Nombre"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.sku"
                            :rules="nameRules"
                            label="SKU"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="2"
                    >
                        <v-text-field
                            v-model="bundle.ext_sku"
                            label="SKU externo"
                            required
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.ean"
                            label="EAN"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-text-field
                            v-model="bundle.price"
                            :rules="priceRules"
                            label="Precio"
                            required
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete
                            :items="productsList"
                            v-model="selectProducts"
                            label="Productos del bundle"
                            multiple
                            chips
                        :return-object="true"
                        >
                        </v-autocomplete>

                        <!-- <v-select
                            v-model="selectProducts"
                            :items="productsList"
                            label="Productos del bundle"
                            multiple
                            chips
                            :return-object="true"
                        ></v-select> -->
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.high"
                            :rules="priceRules"
                            label="Altura"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.width"
                            :rules="priceRules"
                            label="Ancho"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.depth"
                            :rules="priceRules"
                            label="Profundidad"
                            required
                            suffix="cm"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                    <v-col
                    cols="12"
                    md="3"
                    >
                        <v-text-field
                            v-model="bundle.weight"
                            :rules="priceRules"
                            label="Peso"
                            required
                            suffix="kg"
                            step="0.001"
                            min="0.000"
                            type="number"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" md="4" >
                        <v-select
                            v-model="bundle.category_id"
                            :items="categoriesList"
                            :rules="categoryRules"
                            label="Categoría"
                            required
                        ></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-autocomplete
                            :items="brandsList"
                            v-model="bundle.brand_id"
                            label="Marca"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="6" md="3">
                        <img style="max-width:100%; width:150px;" v-bind:src="bundle.imagecover_url_preview" /> 
                    </v-col>
                     <v-col cols="6" md="4">
                        <v-file-input
                        v-model="bundle.imagecover"
                        color="deep-purple accent-4"
                        label="Subir Foto Producto"
                        placeholder="Sube una archivo jpeg o png."
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                        @change="uploadFileProductBundle()"
                        :disabled="loading"
                        :clearable="true"
                        >
                        </v-file-input>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                    cols="12"
                    md="4"
                    >
                    <v-btn color="primary" :disabled="loading" @click="sendFormBundle">Guardar cambios</v-btn>
                    </v-col>
                    <v-col
                    cols="12"
                    md="4"
                    >
                        <v-switch  v-model="bundle.active"  :label="'Activo'"></v-switch>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                
                <v-data-table
                    v-show="selectProducts.length && mode == 'bundle'"
                    :headers="headers"
                    :items="selectProducts"
                    no-results-text="No hay resultados disponibles"
                    no-data-text="No hay datos disponibles"
                    loading-text="Cargando datos..."
                    class="elevation-1 datatable"
                >
                    <template v-slot:item.quantity="{ item }">
                        <td class="text-center">
                            <v-text-field v-model="item.quantity" type="number" min="1">
                            </v-text-field>
                        </td>
                    </template>
                </v-data-table>
            
            </v-card-text>
        </v-card>
    </v-dialog>

</template>

<script>
import EventBus from '@/event-bus';
export default {
    data(){
        return {
            valid:true,
            showDialog: false,
            mode: "product",
            product: {
                name: '',
                ean: '',
                sku: '',
                ext_sku: undefined,
                price: undefined,
                category_id: undefined,
                brand_id: undefined,
                high: 0.0,
                width: 0.0,
                depth: 0.0,
                weight: 0.0,
                active: false,
                imagecover: undefined,
                imagecover_uploaded: false,
                imagecover_url: undefined,
                imagecover_url_preview : "https://s3.us-west-1.amazonaws.com/fullkom.oms/starkoms_placeholder.png",
                imagecover_url_json : "",
            },
            bundle: {
                name: '',
                ean: '',
                sku: '',
                ext_sku: undefined,
                price: undefined,
                category_id: undefined,
                brand_id: undefined,
                products: [],
                active: false,
                high: 0.0,
                width: 0.0,
                depth: 0.0,
                weight: 0.0,
                imagecover: undefined,
                imagecover_uploaded: false,
                imagecover_url: undefined,
                imagecover_url_preview : "https://s3.us-west-1.amazonaws.com/fullkom.oms/starkoms_placeholder.png",
                imagecover_url_json : "",
            },
            nameRules: [
                v => !!v || 'El Campo es requerido',
                //v => v.length <= 10 || 'asdf',
            ],
            priceRules: [
                v => !!v || 'El Campo es requerido',
                v => v > 0 || 'Debes ingresar un valor mayor a 0'
            ],
            categoryRules: [
                (v) => !!v || "Debes elegir una opción"
            ],
            loading: false,
            productsList: [],
            categoriesList: [],
            selectProducts: [],
            brandsList: [],
            headers: [
                {text: 'Producto', value: 'text'},
                {text: 'Cantidad', value: 'quantity'}
            ],
            product_id: undefined,
            product_name: undefined,
        }
    },
    created(){
        var vm = this;
        this.$parent.$on('openDialogEditarProducto', (product,brandsList,categoriesList) => {
            this.product_id = product.id;
            this.product_name = product.name;
            this.productsList = [];
            this.brandsList = brandsList;
            this.categoriesList = categoriesList;
            this.axios({
                url: 'order/products_data_list',
                method: 'GET'
            }).then( response => {  
                vm.productsList = response.data.map(e => ({...e, quantity: 1}));
                vm.axios({
                    url: 'order/products/'+vm.product_id,
                    method: 'GET',
                }).then( response2 => {
                    vm.mode = response2.data.is_bundle ? 'bundle' : 'product';
                    if(response2.data.is_bundle){
                        console.log(response2.data.product);
                        vm.bundle = response2.data.product;
                        
                        if (response2.data.product.cover_image  == null) {
                            vm.bundle.imagecover_url_preview = "https://s3.us-west-1.amazonaws.com/fullkom.oms/starkoms_placeholder.png";
                        }
                        else{
                            console.log(JSON.parse(response2.data.product.cover_image));
                            console.log(JSON.parse(response2.data.product.cover_image).image_1000);
                            vm.bundle.imagecover_url_preview = JSON.parse(response2.data.product.cover_image).image_1000;
                        }

                        vm.selectProducts = [];
                        response2.data.bundle_products.forEach(elem => {
                            let producto = vm.productsList.find(item => item.value == elem.value);
                            if(producto){
                                producto.quantity = elem.quantity;
                                vm.selectProducts.push(producto);
                            }
                        });
                    }else{
                        console.log(response2.data.product);
                        vm.product = response2.data.product;
                        if (response2.data.product.cover_image  == null) {
                            vm.product.imagecover_url_preview = "https://s3.us-west-1.amazonaws.com/fullkom.oms/starkoms_placeholder.png";
                        }
                        else{
                            console.log(JSON.parse(response2.data.product.cover_image));
                            console.log(JSON.parse(response2.data.product.cover_image).image_1000);
                            vm.product.imagecover_url_preview = JSON.parse(response2.data.product.cover_image).image_1000;
                        }
                        
                        
                    }
                    vm.showDialog = true;
                }).catch( error => {
                    console.log(error);
                });
            }).catch( error => {
                console.log(error);
            })
        });
        // this.fetchCategories();
        // this.fetchBrands();
    },
    methods: {
        fetchProducts()
        {
            this.productsList = [];
            var vm = this;
            this.axios({
                url: 'order/products_data_list',
                method: 'GET'
            }).then( response => {  
                
                vm.productsList = response.data.map(e => ({...e, quantity: 1}));
            }).catch( error => {
                console.log(error);
            })
        },
        fetchCategories(){
            this.categoriesList = [];
            var vm = this;
            this.axios({
                url: "inventory/categories_data_list",
                method: "GET",
            })
            .then((response) => {
                vm.categoriesList = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        fetchBrands(){
            this.brandsList = [];
            var vm = this;
            this.axios({
                url: "/inventory/brands_list",
                method: "GET",
            })
                .then((response) => {
                vm.brandsList = response.data;
                })
                .catch((error) => {
                console.log(error);
                });
        },
        sendFormProduct()
        {
            this.loading = true;
            var vm = this;
            this.axios({
                url: 'order/products/product/'+this.product_id,
                method: 'PUT',
                data: this.product
            }).then( response => {
                vm.loading = false;
                vm.$bvToast.toast('Producto actualizado correctamente', {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.$emit('success');
                vm.showDialog = false;
            }).catch( error => {
                vm.loading = false;
                console.log(error);
            });
        },
        sendFormBundle()
        {
            this.loading = true;
            var vm = this;
            this.axios({
                url: 'order/products/bundle/'+this.product_id,
                method: 'PUT',
                data: {...this.bundle, products: this.selectProducts}
            }).then( response => {
                vm.loading = false;
                vm.$bvToast.toast('Bundle actualizado correctamente', {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.$emit('success');
                vm.showDialog = false;
            }).catch( error => {
                vm.loading = false;
                console.log(error);
            });
        },
        uploadFileProduct()
        {
        let product = this.product;
            if(this.product.imagecover instanceof File && !this.loading){
                console.log('se tiene que subir el archivo');
                this.loading = true;
                this.$nextTick(() => {
                    let formData = new FormData();
                    formData.append("file_image_cover", this.product.imagecover, this.product.imagecover.name);
                    var vm = this;
                    this.axios({
                        url: 'inventory/products/upload_cover_image',
                        data: formData,
                        method: 'POST'
                    }).then( response => {
                    console.log(response);
                    console.log(JSON.stringify(response.data));
                    
                        // vm.product.url = response.data;
                        product.imagecover_url_preview = response.data.image_1000;
                        product.imagecover_url = response.data.image_full;
                        product.imagecover_url_json = JSON.stringify(response.data);

                        vm.product.imagecover_uploaded = true;
                        vm.loading = false;
                        console.log('keweaeae');
                        vm.$bvToast.toast(
                            'Archivo subido con exito. Ahora puedes confirmar la carga presionanao el boton "Guardar"',
                            {
                                title: `Notificación`,
                                variant: "success",
                                solid: true,
                                toaster: "b-toaster-bottom-center",
                            }
                        );
                        
                    }).catch( error => {
                        vm.loading = false;
                });
                })
            }
        },
        uploadFileProductBundle()
        {
        let bundle = this.bundle;
            if(this.bundle.imagecover instanceof File && !this.loading){
                console.log('se tiene que subir el archivo');
                this.loading = true;
                this.$nextTick(() => {
                    let formData = new FormData();
                    formData.append("file_image_cover", this.bundle.imagecover, this.bundle.imagecover.name);
                    var vm = this;
                    this.axios({
                        url: 'inventory/products/upload_cover_image',
                        data: formData,
                        method: 'POST'
                    }).then( response => {
                    console.log(response);
                    console.log(JSON.stringify(response.data));
                    
                        // vm.product.url = response.data;
                        bundle.imagecover_url_preview = response.data.image_1000;
                        bundle.imagecover_url = response.data.image_full;
                        bundle.imagecover_url_json = JSON.stringify(response.data);

                        vm.bundle.imagecover_uploaded = true;
                        vm.loading = false;
                        console.log('keweaeae');
                        vm.$bvToast.toast(
                            'Archivo subido con exito. Ahora puedes confirmar la carga presionanao el boton "Guardar"',
                            {
                                title: `Notificación`,
                                variant: "success",
                                solid: true,
                                toaster: "b-toaster-bottom-center",
                            }
                        );
                        
                    }).catch( error => {
                        vm.loading = false;
                });
                })
            }
        },
    },
    mounted()
    {
        
    }
}
</script>